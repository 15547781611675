<template>
  <div class="range-slider d-flex mt-4 justify-content-center">
    <validation-provider
      :rules="field.validations"
      :vid="field.name"
      v-slot="{ errors }"
    >
    <div class="slider-box p-3">
      <span class="d-sm-none range-slider-label">
        {{ field.label }}
      </span>
      <div class="row">
        <div class="col-12 col-md-3 m-auto">
          <h5 class="d-none d-sm-flex range-slider-label">
            {{ field.label }}
          </h5>
          <span class="text-black-50 d-none d-sm-flex" v-if="field.description">
            <small>
              {{ field.description }}
            </small>
          </span>
        </div>

        <b-form-input
          class="d-flex ml-3 mt-auto mb-auto m-md-auto col-7 col-md-5"
          type="range"
          v-model="fieldValue"
          :min="field.min"
          :max="field.max"
          :step="field.step"
        />


        <div class="range-value col-4 col-md-3 m-auto d-flex justify-content-center">
          <div>
            <div
              class="d-flex justify-content-end"
              :class="[tooltip ? '' : ['mb-3', 'mt-2'] ]"
            >
              <span class="text-black-50" notranslate>{{ fieldValue }}</span>
              <span
                class="text-black-50 pl-1 m-auto d-none d-sm-block"
                v-if="field.value_text"
              >
                {{ field.value_text }}
              </span>
            </div>
            <div v-if="tooltip">
            <span
              :id="`${field.name}-tooltip`"
              class="font-italic small egx-partner-color d-flex justify-content-end"
            >
              {{ tooltip.text }}
            </span>
              <b-tooltip
                :target="`${field.name}-tooltip`"
                triggers="hover"
                :placement="'auto'"
              >
                {{ tooltip.body }}
              </b-tooltip>
            </div>
          </div>
        </div>

        <span class="col-12 text-center text-black-50"> {{ errors[0] }} </span>
      </div>
    </div>
    </validation-provider>
  </div>
</template>

<script>
/* eslint-disable camelcase  */
import { ValidationProvider, extend } from 'vee-validate';
import { required, min_value, max_value } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'This field is required',
});

extend('min_value', {
  ...min_value,
  message: 'This field must be at least {min}',
});

extend('max_value', {
  ...max_value,
  message: 'This field must be at less than {max}',
});

export default {

  components: {
    ValidationProvider,
  },

  props: {
    field: Object,
    sectionTitle: String,
  },

  computed: {
    currentGenericItem() {
      return this.$store.state.generic_items.currentGenericItems[this.sectionTitle];
    },

    tooltip() {
      return this.field.tooltip ? this.field.tooltip : false;
    },

    fieldValue: {
      get() {
        if (this.currentGenericItem.data[this.field.name][0] === undefined) {
          this.updateRangeValue(this.field.default || this.field.min);
          return (this.field.default || this.field.min);
        }
        return this.currentGenericItem.data[this.field.name][0];
      },
      set(newValue) {
        this.updateRangeValue(newValue);
      },
    },
  },

  methods: {
    updateRangeValue(value) {
      const currentGenericItemData = this.currentGenericItem;
      currentGenericItemData.data[this.field.name] = [value];

      const obj = { key: this.sectionTitle, value: currentGenericItemData };

      this.$store.commit('generic_items/setSectionData', obj);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../assets/stylesheets/components/range-slider.scss";
</style>
